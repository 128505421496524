<template>
  <div>
      
	<!-- partial -->
<div class="container-top">
	<div class="row addbackground">
		<div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
		<div class="bg-stark-img col-xs-12 col-sm-12 col-md-10 col-lg-8">
			<div class="topic text-start">
				<div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4">
					<table>
						<tr>
							<td width="40"><img src="../assets/images/topic01.png" width="40" height="16" class="img-responsive"></td>
							<td class="black text-center"><img src="../assets/images/topic02.png" height="16" class="img-responsive"></td>
							<td width="50"><img src="../assets/images/topic03.png" width="50" height="16" class="img-responsive"></td>
						</tr>
						<tr>
							<td><img src="../assets/images/topic08.png" width="40" height="20" class="img-responsive"></td>
							<td class="black"><p>{{name}}</p></td>
							<td><img src="../assets/images/topic04.png" width="50" height="20" class="img-responsive"></td>
						</tr>
						<tr>
							<td><img src="../assets/images/topic07.png" width="40" height="24" class="img-responsive"></td>
							<td class="bg-bt"><img src="../assets/images/topic06.png" class="img-responsive"></td>
							<td><img src="../assets/images/topic05.png" width="50" height="24" class="img-responsive"></td>
						</tr>
					</table>
				</div>
				<div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>
				<div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>
			</div>
			<div class="row">
				<div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3"><div class="mb black"><p>{{name}}</p></div></div>
				<div class="text-center col-4 col-xs-4 col-sm-4 col-md-4 col-lg-6"><p class="rate">อัตรา<br>การชนะ</p><p class="percent">{{percent}}<span>%</span></p></div>
				<div class="text-center col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3"><p class="countdown">{{countdown}}</p><p class="t-reset">TIME RESET</p></div>
			</div>
			<div class="row">
				<div class="time text-end col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3"><p style="font-size:20px;padding-left:10px;">{{duration}}</p></div>
				<div class="col-4 col-xs-4 col-sm-4 col-md-4 col-lg-6"></div>
				<div class="btn-switch col-4 col-xs-4 col-sm-4 col-md-4 col-lg-3"><a href="#" @click="goback"><img src="../assets/images/btn-switch.png" width="140" height="59" class="text-center img-responsive"></a></div>
			</div>
		</div>
		<div class="col-xs-0 col-sm-0 col-md-1 col-lg-2"></div>
	</div>
</div>
<div>
	<iframe v-if="url" :src="url" title="games"></iframe> 
</div>
  </div>
</template>
<script>
import firebase, { database } from "firebase/app"
import "firebase/firestore"
import "firebase/database"
import 'firebase/auth'
import moment from 'moment-timezone';
export default{
    created(){
		const fullurl = localStorage.getItem("url");
        const url = fullurl.split('/');
        console.log(url);
        if(url[2]){
            if(url[2]==='nttech-sg0.ppgames.net' && url[4]){
              const names = url[4].split('&');
              if(names[1]){
                  this.name = decodeURI(names[1].replace('gname=',''));
              }
          }else if(url[2]==='game.3yjt.com'){
              this.name = 'KING MAKER';
          }else if(url[2]==='lobby.silverkirinplay.com' && url[7]){
              this.name = url[7].toUpperCase()
          }else if(url[2]==='egame.multi888.com'){
              this.name = 'egame.multi888'.toUpperCase()
          }else if(url[2]==='play.pgslot.co'){
              this.name = 'PG GAMES'.toUpperCase()
          }else if(url[2]==='www.gwc688.net'){
              this.name = 'XO SLOT';
          }else if(url[2]==='games.ambslot.com'){
              this.name = 'AMB SLOT';
          }else if(url[2]==='lobby.argonplay.com'){
              this.name = 'Spade gaming';
          }else if(url[2]==='funky.ttstations.com'){
              this.name = 'Funky game';
          }else if(url[2]==='wsx.mayousa.com'){
              this.name = 'Funta gaming';
          }else if(url[2]==='gamelaunch.wazdan.com'){
            this.name = 'Wazdan direct';
          }else if(url[2]==='wbgame.sssjlsss.com'){
            this.name = 'Jili';
          }else if(url[2]==='lobby-ahvgl62wwq-df.a.run.app'){
            this.name = 'Iconic gaming';
          }else if(url[2]==='booongo2-dgm.gv-gamespace.com'){
            this.name = 'Booongo';
          }else if(url[2]==='purple.romadeluxe.com'){
            this.name = 'Allwayspin';
          }else if(url[2]==='629xyss.fafafa3388.com'){
            this.name = 'Ameba';
          }else if(url[2]==='cors.mannagaming.com'){
            this.name = 'Manna play';
          }else if(url[2]==='gamessea.kaga88.com'){
            this.name = 'KAgaming';
          }else if(url[2]==='static.shycfjwz.com'){
            this.name = 'Askmebet';
          }else if(url[2]==='static-prod-tw.dcgames.fun'){
            this.name = 'Yggdrasil';
          }else if(url[2]==='frerpt.kathilag.com'){
            this.name = 'Micro gaming';
          }else if(url[2]==='w1.bluecave88.com'){
            this.name = 'Live22';
          }else if(url[2]==='sg.sp-platform.com'){
            this.name = 'Simpleplay';
          }else if(url[2]==='play.8provider.com'){
            this.name = 'Evo play';
          }else if(url[2]==='api.ayitang.com'){
            this.name = 'Gametron';
          }else{
              this.name = url[3].toUpperCase()
          }
            
        }
        this.url = fullurl;
        // this.$store.dispatch("casino/getGames", this.game);
        const user = firebase.auth().currentUser;
        if(user){
            this.user = user.displayName
        }else{
            this.$router.replace('/'); 
        }
      // this.randomCoin();
    },
    data() {
        return {
            url:'',
            user:'',
            name:'',
            duration:'',
            createdate:'',
            countdown:10,
            percent:0,
        }
    },
    computed: {
        games() {
            return this.$store.getters["casino/games"];
        }
    },
    async mounted(){
        if(this.user){
            const users = (await firebase.database().ref(`/users2`).orderByChild("username").equalTo(this.user).once("value")).val();
            if (!users) {
            this.$router.replace('/');
            }else{
                let user = {};
                for (const x in users) {
                    user = users[x];
                }
                if(user.status !== 'Y'){
                    alert('โปรดติดต่อ Admin');
                    this.$router.replace('/');
                }
                const date = moment(user.createdate,'YYYY-MM-DD HH:mm:ss').add(2,'hours').format('YYYYMMDDHHmmss')
                const now = moment().tz('Asia/Bangkok').format('YYYYMMDDHHmmss')
                if(date < now){
                    alert('โปรดติดต่อ Admin');
                    this.$router.replace('/');
                }else{
                    this.createdate = user.createdate;
                    this.calculateLeftTime()
                }
                this.randomPercent(80,100);
                setInterval(() => {
                    this.countdown--;
                    if(this.countdown == 0){
                        this.countdown = 10;
                        this.randomPercent(60,100);
                    }
                }, 1000);
                //const createdate = user.createdate.replace('-','');
            }
            // this.commentsRef = firebase.database().ref('onlineuser');
            // this.commentsRef.once('value').then((snapshot) => {
            //     this.onlineUser = snapshot.val().value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            // });
            
            // this.commentsRef.on('child_changed', (data2) => {
            //     this.onlineUser = data2.val().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            // });
        }
    },
    methods: {
        randomPercent(min, max){
            this.percent = Math.floor(Math.random() * (max - min) ) + min;
        },
        goback(){
            this.$router.replace('/home');
        },
        calculateLeftTime(){
                const end = moment(this.createdate,'YYYY-MM-DD HH:mm:ss').add(2,'hours')
                var now2 = moment(new Date());
                var duration = moment.duration(end.diff(now2));
                var hours = duration.asHours();
                this.hours = Math.floor(hours);0

                //Get Days and subtract from duration
                
                //Get hours and subtract from duration
                var hours = duration.hours();
                duration.subtract(moment.duration(hours,'hours'));
                
                //Get Minutes and subtract from duration
                var minutes = duration.minutes();
                duration.subtract(moment.duration(minutes,'minutes'));
                this.duration = `${hours} h : ${minutes} m`;
        }
    }
}
</script>
<style scoped>
.addbackground{
	background-image: url("https://firebasestorage.googleapis.com/v0/b/imith-50bd2.appspot.com/o/images%2Frandom.gif?alt=media");
	background-repeat: no-repeat;
  	background-size: auto;
	background-position: center;
}
</style>