import firebase from 'firebase/app'
import 'firebase/database'
export default {
    namespaced: true,
    state: {
        games:[],
        commentsRef:null
    },
    mutations: {
        setGames(state:any, result:any) {
            state.games = result
        },
    },
    actions: {
        getGames({commit,dispatch,state}:any, game:string) {
            let data:any = [];
            state.commentsRef = firebase.database().ref('casino/'+game);
            state.commentsRef.once('value').then((snapshot:any) => {
                const data2:any = [];
                snapshot.forEach((doc:any)=>{
                    const data = doc.val();
                    data.key = doc.key;
                    data2.push(data)
                });
                data = data2.sort((a:any, b:any) => {
                    if (a.order < b.order) return -1;
                    if (a.order > b.order) return 1;
                    return 0;
                });
                commit('setGames', data)
            });
            
            state.commentsRef.on('child_changed', (data2:any) => {
                if(data2.key) {
                    const index = data.findIndex((item: any) => item.key === data2.key);
                    data[index].percent = data2.val().percent
                }
            });
        },
        disconnect({commit,dispatch,state}:any){
            if(state.commentsRef != null){
                state.commentsRef.off()
            }
            commit('setGames', [])
        }
    },
    getters: {
        games : (state:any) => {
            return state.games
        },
    }
}